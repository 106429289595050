import { Link } from "gatsby";
import React, { Component } from "react";
import packageJson from "../../package.json";
import Logo from "../images/logo.png";

const navigation = {
  organisation: [
    { name: "About Us", href: "/about" },
    { name: "Members", href: "/members" },
    { name: "APEC App Challenges", href: "/apec-challenges" },
  ],
};

export default class Footer extends Component {
  render() {
    return (
      <footer
        className="bg-white border-t"
        aria-labelledby="footer-heading"
        id="contact"
      >
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="mb-8 space-y-4 xl:col-span-1">
              <img className="h-12" src={Logo} alt="DMAP" />
              <p className="text-gray-500 text-base">
                Contact Us : <u><a target="_blank" rel="noreferrer" href="mailto:info@dmap-community.org">info@dmap-community.org</a></u>
              </p>
            </div>
            <div className="grid grid-cols-2 gap-8 xl:col-span-4">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Organization
                  </h3>
                  <ul className="mt-4 space-y-4">
                    {navigation.organisation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className="text-base text-gray-500 hover:text-blue-600"
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0"></div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8"></div>
            </div>
            <div className="mt-12 xl:mt-0"></div>
          </div>
          <div className="mt-8 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2"></div>
            <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
              &copy; 2021 Digital Makers Asia Pacific Organization. All rights
              reserved. V-{packageJson.version}
            </p>
          </div>
        </div>
      </footer>
    );
  }
}
