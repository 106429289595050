import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link } from "gatsby";
import React, { Component, Fragment } from "react";
import Favicon from "../images/logo.png";

const navigation = [
  { name: "About Us", href: "/about" },
  { name: "Members", href: "/members" },
  { name: "Blogs", href: "/blogs" },
  { name: "APEC App Challenges", href: "/apec-challenges" },
  { name: "Contact", href: "#contact" },
];

export default class Header extends Component {
  renderLink = (type: string) => {
    let className = "";
    const linkView: any[] = [];
    if (type === "desktop") {
      className = "font-medium text-gray-500 hover:text-blue-600";
    } else {
      className =
        "block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50";
    }

    navigation.map((item) => {
      if (item.href.includes("#")) {
        linkView.push(
          <a href={item.href} key={item.name} className={className}>
            {item.name}
          </a>
        );
      } else {
        linkView.push(
          <Link key={item.name} to={item.href} className={className}>
            {item.name}
          </Link>
        );
      }
      return null;
    });

    return linkView;
  };

  render() {
    return (
      <Popover>
        <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
          <nav
            className="relative flex items-center justify-between sm:h-10 md:justify-start"
            aria-label="Global"
          >
            <div className="flex items-center flex-grow flex-shrink-0 md:flex-grow-0">
              <div className="flex items-center justify-between w-full md:w-auto">
                <Link to="/">
                  <span className="sr-only">DMAP</span>
                  <img className="h-10 w-auto sm:h-11" src={Favicon} alt="" />
                </Link>
                <div className="-mr-2 flex items-center md:hidden">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-7">
              {this.renderLink("desktop")}
            </div>
          </nav>
        </div>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img className="h-10 w-auto" src={Favicon} alt="" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                    <span className="sr-only">Close main menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3 space-y-1">
                {this.renderLink("mobile")}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    );
  }
}
